body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {display:none;}

.carousel-image {
  object-fit: cover;
  height: 100vh;
  width: 100vw;
}

